import "css/globals.css";

import { Button, Colors, Icon } from "@zapier/design-system";
import { styled } from "lib/theme";
import { fontSans } from "lib/fonts";
import { cn } from "utils/cn";
import { config } from "@/config";

const Wrapper = styled.section`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Colors.GrayWarm2};
`;

const InnerWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  padding: 30px 0;
  border-radius: 12px;
  border: 1px solid ${Colors.GrayWarm4};
  background-color: var(--zds-background-weaker, ${Colors.PrimeWhite});
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LogoText = styled.h1`
  font-size: 24px;
  font-weight: 600;
`;

const ExplainText = styled.h2`
  font-size: 18px;
  color: ${Colors.GrayWarm10};
  text-align: center;
`;

export function Error500() {
  return (
    <Wrapper className={cn("font-sans", fontSans.variable)}>
      <InnerWrapper>
        <LogoWrapper>
          <Icon isBlock color="BrandOrange" name="navInterfaces" size={30} />
          <LogoText>Interfaces</LogoText>
        </LogoWrapper>
        <ExplainText>An unexpected error has occured.</ExplainText>
        <Button href={`${config().NEXT_PUBLIC_INTERFACES_BASE_URL}/interfaces`}>
          Back to home
        </Button>
      </InnerWrapper>
    </Wrapper>
  );
}
